<template>
	<div>


	<template v-if="type == 'four' ">

	<div class="Row4" :class="'Row4' + context" :style="{height: whatRow4Height}">

		<div class="Row4--bg" v-if="context != '-mobile'">
			<div class="Row4--bg--color" v-for="(number, index) in 4" :key="index"></div>
		</div>

		<div class="Row4--container" :class="'Row4' + context" :style="{height: whatRow4ContainerHeight}"
		v-for="(element, index) in content.content"
		:key="index">

			<CaseProduct
			:content="element.content"
			:type="type"
			:context="context"
			></CaseProduct>

<!-- 			<template v-if="context != '-mobile' && content.content.length < 2 && index == content.content.length">
				lastcase
			</template> -->

		</div>

		
	</div>

	</template>


	<template v-if="type == 'two' ">
	<div class="Row2" :class="'Row2' + context" :style="{height: whatRow2Height}">
		<div class="Row2--container" :class="'Row2' + context" :style="{height: whatRow2ContainerHeight}"
		v-for="(element, index) in content.content"
		:key="index">

			<CaseProduct
			:content="element.content"
			:type="type"
			:context="context">
			</CaseProduct>

		</div>

	</div>
	</template>


	</div>
</template>

<script>
import CaseProduct from '@/components/boutique/boutique/cardproducts/CaseProduct2.vue'

export default {
  name: 'Row4',
  props: ['content', 'type', 'context'],
  components: {
    CaseProduct,
  },
	data: function(){
		return {

		}
  },
	computed:{
		whatRow4Height: function(){
			if (this.context == '-mobile'){
				if (this.content.content.length > 2){
					return '100vw'
				}else {
					return '50vw'
				}
			}else {
				return ''
			}
		},
		whatRow4ContainerHeight: function(){
			if (this.context == '-mobile'){
				if (this.content.content.length > 2){
					return '50%'
				}else {
					return '100%'
				}
			}else {
				return ''
			}
		},

		whatRow2Height: function(){
			if (this.context == '-mobile'){
				if (this.content.content.length > 1){
					return '150vw'
				}else {
					return '75vw'
				}
			}else {
				return ''
			}
		},
		whatRow2ContainerHeight: function(){
			if (this.context == '-mobile'){
				if (this.content.content.length > 1){
					return '50%'
				}else {
					return '100%'
				}
			}else {
				return ''
			}
		},

  },
  methods: {

 
  },
  created(){
		// console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.Row4{
	position: relative;
	display: flex;
	width: 100%;
	height: 20vw;

	overflow: hidden; 

	&--bg{
		position: absolute;
		width: 100%;
		height: 100%;

		display: flex;

		div:nth-child(odd){
			background-color: var(--shop-color-lighten);
		}

		div:nth-child(even){
			background-color: var(--shop-color-lighter);
		}

		&--color{
			width: 25%;
		}
	}

	&--container{
		width: 25%;
		height: 100%;
		position: relative;

		&--element{

		}
	}
}

.Row2{
	position: relative;
	display: flex;
	width: 100%;
	height: 35vw;

	overflow: hidden; 

	&--container{
		width: 50%;
		height: 100%;
		position: relative;

		&--element{

		}
	}
}


.Row4.Row4-mobile{
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	width: 100%;
	height: 80vw;	
}

.Row4--container.Row4-mobile{
		width: 50%;
		height: 50%;
		position: relative;
}


.Row2.Row2-mobile{
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	width: 100%;
	height: 120vw;	

}

.Row2--container.Row2-mobile{
		width: 100%;
		height: 50%;
		position: relative;

}

</style>