<template>
  <div class="CardProducts">

  <div class="CardProducts--product-container">
    
<!--     <component 
    class="CardProducts--product-container--element"
    :class="'cardproduct' + whatConfig"
    :is="whatComponent(element.type)"
    v-for="(element, index) in content"
    :key="index"
    :content="element.content"
    :context="whatConfig">
    </component> -->


    <!-- AJOUTER ABONNEMENTS X2 -->

    <RowAbo :content="content.abonnements" :context="whatConfig"></RowAbo>

    <component 
    class="CardProducts--product-container--row"
    :class="'cardproduct' + whatConfig"
    :is="whatComponent(element)"
    v-for="(element, index) in content.products"
    :key="index"
    :content="element"
    :context="whatConfig"
    :type="element.type">
    </component>

  </div>


  </div>
</template>

<script>
import Row4 from '@/components/boutique/boutique/cardproducts/Row4.vue'
import Row2 from '@/components/boutique/boutique/cardproducts/Row2.vue'
import RowAbo from '@/components/boutique/boutique/cardproducts/RowAbo.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'CardProducts',
  mixins: [clientConfigs],
  props: ['content'],
  components: {
    Row4,
    Row2,
    RowAbo
  },
  computed:{

    whatConfig: function(){

      if (this.ClientFormat == 'portrait'){

        if (this.ClientWidth == 'mobileWidth'){
          return '-mobile'
        }else {
          return '-portrait'
        }
        
      }else {
        return ''
      }
    },
  },
  
  methods: {

    // whatComponent: function(type){

    //   let component

    //   if (type == 'product'){
    //     component = 'CaseProduct'
    //   }
    //   if (type == 'news'){
    //     component = 'CaseNews'
    //   }
    //   if (type == 'abo'){
    //     component = 'CaseAbo'
    //   }

    //   return component
    // }

    whatComponent: function(element){

      // console.log(element)

      if (element.type == 'four'){
        return 'Row4'
      }

      if (element.type == 'two'){
        // console.log('whatComponent two')
        return 'Row4'
      }

      // if (element.length == 2){
      //   // console.log('row 2', element)
      //   return 'Row2'
      // }

      // if (element.length == 4){
      //   // console.log('row 4', element)
      //   return 'Row4'
      // }

    }
  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">

// .CardProducts{
// 	color: black;
//   width: 100%;
//   height: auto;

//   background-color: var(--shop-bg-color);

//   overflow: hidden;

//   &--product-container{
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//     // background-color: var(--shop-bg-color);
//     // background-color: var(--second-color);
//     background-color: var(--shop-color-lighten);

//     &--element{
//       width: calc(100% / 3);
//       height: calc(100vw / 3);
      
//       &--img{
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }

//       &--text-container{

//       }
//     }
//   }
// }

// .CardProducts--product-container--element.cardproduct-mobile{
//       width: calc(100% / 2);
//       height: calc(100vw / 2);
// }

.CardProducts{
  position: relative;
  background-color: var(--shop-bg-color);
  width: 100%;
  height: auto;

  &--product-container{

    width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    background-color: var(--shop-color-lighten);
    position: relative;

  }
}





</style>