<template>
  <router-link 
  class="CaseAbo2"
  v-bind:to=" '/' + $route.params.language + '/boutique/' + content.uid">

		<div class="CaseAbo2--content" :class="'CaseAbo2-' + index">
      <p class="CaseAbo2--content--title" v-html="content.text"></p>
    </div>

    <div class="CaseAbo2--more">
      <p class="CaseAbo2--more--description" v-html="content.description"></p>
      <p class="CaseAbo2--more--pricing" v-html="content.pricing"></p>
    </div>

  </router-link>
</template>

<script>
export default {
  name: 'CaseAbo2',
  props: ['content', 'index'],
  computed:{

  },
  methods: {

  },
  created(){
		// console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.CaseAbo2{
  width: 100%;
  height: 100%;

  // position: relative;
  
	&--content{
		
    display: flex;
    flex-direction: column;
    justify-content: center;
    
		width: 100%;
		height: 100%;

    &.CaseAbo2-0{
      background-color: var(--shop-color-lighten);
    }

    &.CaseAbo2-1{
      background-color: var(--shop-color-lighter);
    }

    transition: transform .35s ease;
    &:hover{
      transform: scale(1.05);
    }
		& p{
			margin: var(--esp-med);
			font-size: var(--shop-font-card-heavy);
			text-align: center;
			color: white;
		}
	}
}


.CaseAbo2--more{
  padding: var(--esp-med);  
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  
  width: calc(100% - 2 * var(--esp-med));

  // bottom: -100px;  
  z-index: 100;
  bottom: 0;  
}


</style>