<template>
  <router-link 
  class="CaseProduct2"
  v-bind:to=" '/' + $route.params.language + '/boutique/' + content.uid">
<!--   @mouseenter.native="mouseEnter()"
  @mouseleave.native="mouseLeave()"> -->
  

    <div class="CaseProduct2--bg"></div>

    <transition name="caseProduct2ToggleInvert" mode="out-in">

    <div class="CaseProduct2--img-container" 
    v-if="!hoverIsVisible"
    >

      <img class="CaseProduct2--img-container--img" 
      v-lazyloading
      :src="content.cover.thumb" 
      :data-srcset="content.cover.srcset"
      sizes="60vw">

    </div>

    </transition>


    <div class="CaseProduct2--text-container">

      <div class="CaseProduct2--text-container--content">

        <div class="CaseProduct2--text-container--content--title" v-html="content.title_cover"></div>
        <div class="CaseProduct2--text-container--content--price">{{content.price}}€</div>

      </div>

    </div>

    <!-- <template v-if="context == '-mobile' ? false: true"> -->

    <transition name="caseProduct2Toggle" mode="out-in">

    <div 
    class="CaseProduct2--hover-container"
    v-if="hoverIsVisible">
    <!-- v-if="hoverIsVisible" -->

      <div class="CaseProduct2--hover-container--content">
      
        <div class="CaseProduct2--hover-container--content--date">{{content.date}}</div> 
        <div class="CaseProduct2--hover-container--content--description" v-html="content.description"></div>  

      </div>  

    </div>

    </transition>

    <!-- </template> -->

  </router-link >

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'CaseProduct2',
  directives:{
    LazyLoading
  },
  props: ['content', 'context'],
  data: function(){
    return{
      hoverIsVisible: false
    }
  },
  computed:{


  },
  methods: {
    mouseEnter: function(){
      if (this.context != '-mobile'){
        this.hoverIsVisible = true
      }
    },
    mouseLeave: function(){
      // console.log('mouseLeave')
      this.hoverIsVisible = false      
    }

  },
  created(){
    // console.log(this.context)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.caseProduct2Toggle-enter{
  transition: transform .45s ease;
  transform: translate(0, 100%);
}

.caseProduct2Toggle-enter-active {
  transition: transform .45s ease;
}

.caseProduct2Toggle-enter-to{
  transition: transform .45s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.caseProduct2Toggle-leave{
  transition: transform .45s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.caseProduct2Toggle-leave-active{
  transition: transform .45s ease;
}

.caseProduct2Toggle-leave-to{
  transition: transform .45s ease;
  transform: translate(0, 100%);
}

////////

.caseProduct2ToggleInvert-enter{
  transition: transform .45s ease;
  transform: translate(0, -100%);
}

.caseProduct2ToggleInvert-enter-active {
  transition: transform .45s ease;
}

.caseProduct2ToggleInvert-enter-to{
  transition: transform .45s ease;
  // top: 0vh;
  transform: translate(0, 0);
}


.caseProduct2ToggleInvert-leave{
  transition: transform .45s ease;
  // top: 0vh;
  transform: translate(0, 0);
}


.caseProduct2ToggleInvert-leave-active{
  transition: transform .45s ease;
}

.caseProduct2ToggleInvert-leave-to{
  transition: transform .45s ease;
  transform: translate(0, -100%);
}


.CaseProduct2{
  // position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden; 

  &--bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--shop-bg-color);
  }

  &--img-container{
    position: absolute;
    width: 101%;
    height: 100%;

    overflow: hidden; 

    &--img{
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: transform .35s ease;
      transform: scale(1.02);
      &:hover{
        transform: scale(1.15);
      }
    }
  }

  &--text-container{
    position: absolute;
    z-index: 100;
    width: 100%;
    bottom:0;

    font-size: var(--shop-font-labeur-subtitle);

    &--content{
      padding: var(--esp-med);

      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &--title{
        // width: 50%;
      }

      &--price{

      }
    }
  }

  &--hover-container{
    width: 100.1%;
    height: 100%;
    position: absolute;

    font-size: var(--shop-font-labeur);

    top: 0%;
    z-index: 99;
    background-color: var(--shop-bg-color);

    overflow: hidden;   

    // transform: scale(1.1); 

    &--content{
      padding: var(--esp-med);
      height: 100%;

      &--date{

        font-family: 'oli';
        margin-bottom: var(--esp-med);
        font-size: var(--shop-font-labeur-subtitle);

      }

      &--description{

        font-family: 'vd-reg';
        line-height: 1.2;

        line-clamp: 5;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;


        // height: 50%;
        
        & p {
          height: 100%;
          margin-bottom: var(--esp-small);
          text-overflow: ellipsis;
        }

      }

    }
    
  }
  
}

</style>