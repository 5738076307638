<template>
  <div class="PrimeProduct" :class="['primeproduct' + context]">

    <div class="PrimeProduct--slider-container" :class="['primeproduct' + context]">

      <ViewerShop :content="content.gallery"></ViewerShop>

    </div>

    <router-link v-bind:to=" '/' + $route.params.language + '/' + $route.name + '/' + content.uid">
    <div class="PrimeProduct--text-container" :class="['primeproduct' + context]">

      <div class="PrimeProduct--text-container--main-info" :class="['primeproduct' + context]">

      <!-- <router-link v-bind:to=" '/' + $route.params.language + '/' + $route.name + '/' + content.uid"> -->

        <div class="PrimeProduct--text-container--main-info--title" v-html="content.title"></div>
        <div class="PrimeProduct--text-container--main-info--date" v-html="content.date2"></div>
        <div class="PrimeProduct--text-container--main-info--price" >{{content.price}}€</div>

       <!-- </router-link >   -->

      </div>

      

      <div v-if="context != '-mobile'" class="PrimeProduct--text-container--second-info" :class="['primeproduct' + context]">
         <div class="PrimeProduct--text-container--second-info--text" :class="['primeproduct' + context]" v-html="content.description"></div>
      </div>

      
    </div>

    </router-link >

  </div>
</template>

<script>
import ViewerShop from '@/components/boutique/ViewerShop.vue'

export default {
  name: 'PrimeProduct',
  props: ['content', 'context'],
  components: {
    ViewerShop,
  },
  computed:{

  },
  methods: {

  },
  created(){
    
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.PrimeProduct{
    width: 100%;
    height: 100%;

    overflow: hidden;

    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

  &--slider-container{
    width: 100%;
    min-height: 74%;
    height: 100%;


    height: 75vh;
    display: inline-block;

  }

  &--text-container{
    // height: 26%;
     height: auto;


     width: 100%;
     display: inline-block;
  }
}

.PrimeProduct--text-container{
  display: flex;
  // margin: var(--esp-med);
  

  &--main-info{
    width: calc(100% / 3);
    margin-top: var(--esp-med);
    margin-bottom: var(--esp-med);
    margin-left: var(--esp-med);

    font-size: var(--shop-font-labeur-title);

  }

  &--second-info{
    width: calc(100% / 3 * 2);
    font-family: 'vd-reg';

    margin: var(--esp-med);

    overflow: scroll;
    font-size: var(--shop-font-labeur);
    line-height: 1.1;

    & p {
      margin-bottom: var(--esp-small);
    }

    &--text{
      column-count: 2;
    }
  }
}


.PrimeProduct.primeproduct-mobile{
  // display: flex;
  // flex-direction: column;
  // position: relative;

  height: 100%;
  height: auto;
}


.PrimeProduct--slider-container.primeproduct-mobile{
  // height: 50%;
  // min-height: 50%;


  height: 50vh;
  display: inline-block;
  width: 100%;
}

.PrimeProduct--text-container.primeproduct-mobile{
  // height: 50%;
  // margin: var(--esp-big);


  height: auto;
  display: inline-block;
  width: calc(100% - 2 * var(--esp-big));
}

.PrimeProduct--text-container--main-info.primeproduct-mobile{
  width: 100%;
  margin: var(--esp-big);
}

.PrimeProduct--text-container--second-info.primeproduct-mobile{
  width: 100%;
  margin: var(--esp-big);
}

.PrimeProduct--text-container--second-info--text.primeproduct-mobile{
  column-count: 1;
}


/* PORTRAIT */

.PrimeProduct--slider-container.primeproduct-portrait{
  height: 65vh; 
}


</style>