<template>

  <transition :name="whatTransition" appear mode="out-in">

	<div class="boutique" :class="['boutique'+ whatConfig]">

    <!-- <PopCookie></PopCookie> -->

    <component :is="whatHeader" :displayRedirect="false" :context="'boutique'"></component>

		<div class="boutique--content" :class="['boutique'+ whatConfig]">

      <div class="boutique--content--newsletter">
        <NewsletterAnimate></NewsletterAnimate>
      </div>

			<PrimeProduct class="boutique--content--prime-product" :class="['boutique'+ whatConfig]" :content="page.prime_product" :context="whatConfig"></PrimeProduct>
			<CardProducts class="boutique--content--card-products" :content="page.products"></CardProducts>

      <FooterShop :context="'boutique'"></FooterShop>

      <Scrollbar v-if="this.whatComputConfig != '-mobile'" :parent="'boutique-scrollbar'"></Scrollbar>  

		</div>	

  </div>

  </transition> 
  
</template>


<script>
import HeaderShop from '@/components/boutique/HeaderShop.vue'
import HeaderShopMobile from '@/components/boutique/HeaderShopMobile.vue'
// import FooterShop from '@/components/boutique/FooterShop.vue'
import PrimeProduct from '@/components/boutique/boutique/PrimeProduct.vue'
import CardProducts from '@/components/boutique/boutique/CardProducts2.vue'
import NewsletterAnimate from '@/components/NewsletterAnimate.vue'
import FooterShop from '@/components/boutique/FooterShop.vue'

import PopCookie from '@/components/boutique/PopCookie.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
	name: 'boutique',
  mixins: [clientConfigs],
  components: {
    HeaderShop,
    HeaderShopMobile,
    PrimeProduct,
    CardProducts,
    NewsletterAnimate,
    FooterShop,
    Scrollbar,

    PopCookie
  },
	data: function(){
		return {
			state : this.$store.state,
		}
	},
	computed:{
    page: function () { return this.$store.state.[this.$route.params.language].boutique },

    whatConfig: function(){

      if (this.ClientFormat == 'portrait'){

        if (this.ClientWidth == 'mobileWidth'){
          return '-mobile'
        }else {
          return '-portrait'
        }
        
      }else {
        return ''
      }
    },

    whatHeader: function(){

      if (this.ClientWidth == 'mobileWidth'){
        return 'HeaderShopMobile'
      }else {
        return 'HeaderShop'
      }
    }

	},
  watch : {

  },
	methods: {

    initShopify: function(){

      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          this.ShopifyBuyInit();
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },

    loadScript: function(){
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      // script.onload = this.ShopifyBuyInit();
      setTimeout(() => { this.ShopifyBuyInit() }, 800);
    },

	},
  created() {
    setTimeout(()=> { window.scroll(0, 0) }, 60)
		this.$client.isInit = true

    // console.log(this.page)
  },
  mounted(){
    // console.log(this.page)

    setTimeout(()=> { this.$tools.shopToggleAppear() }, 800)
  },


  beforeDestroy(){
    this.$tools.shopToggleHide()
    this.$tools.shopCartHide() 
  }

}

</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/snipcart';
@import '@/css/transition';


.boutique{
  z-index: 2;
  position: absolute;
  // min-height: 100vh;
  // height: auto;

  width: 100%;
  background-color: var(--shop-bg-color);

	&--content{
		margin-top: var(--shop-menu-header-height);

    &--prime-product{
      position: relative;
      // height: 80vh;
      // height: calc(106vh);
    }

    &--card-products{
      position: relative;
      height: auto;
    }
	}
}

.boutique.boutique-mobile{
  min-height: calc(100% - var(--menu-header-height));
}

.boutique--content.boutique-mobile{
  margin-top: calc(var(--menu-header-height) + var(--shop-menu-header-height));
}

.boutique--content--prime-product.boutique-mobile{
  height: auto;
}

.boutique--content--newsletter{
  overflow: hidden;
  position: relative;
  width: 100%;
  height: var(--shop-menu-header-height);
}




.boutique--content--prime-product.boutique-portrait{
  height: auto;
}




</style>