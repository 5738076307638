<template>

  <div class="RowAbo" :class="'RowAbo' + context">
    
    <div class="RowAbo--container" :class="'RowAbo' + context"
    v-for="(element, index) in content"
    :key="index">
    
      <CaseAbo :content="element.content" :index="index"></CaseAbo>

    </div>

  </div>

</template>

<script>
import CaseAbo from '@/components/boutique/boutique/cardproducts/CaseAbo2.vue'

export default {
  name: 'RowAbo',
  props: ['content', 'context'],
  components: {
    CaseAbo
  },
  computed:{

  },
  methods: {

  },
  created(){
		// console.log('RowAbo', this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.RowAbo{
  position: relative;
  display: flex;
  width: 100%;
  height: 20vw;
  overflow: hidden; 

  &--container{
    width: 50%;
    height: 100%;
    position: relative;
  }
}

.RowAbo.RowAbo-mobile{
  height: 50vw;
}

</style>