<template>

	<div class="Row2">

		<div class="Row2--container"
		v-for="(element, index) in content.content"
		:key="index">

			<CaseProduct
			:content="element.content">
			</CaseProduct>

		</div>

	</div>

</template>

<script>
import CaseProduct from '@/components/boutique/boutique/cardproducts/CaseProduct2.vue'

export default {
  name: 'Row2',
  props: ['content'],
  components: {
    CaseProduct,
  },
	data: function(){
		return {

		}
  },
  computed:{


  },
  methods: {

 
  },
	created(){
	}
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.Row2{
	position: relative;
	display: flex;
	// justify-content: space-between;
	width: 100%;
	height: 40vw;

	&--container{
		width: 50%;
		height: 100%;
		position: relative;

		// width: 25vw;
		// height: 25vw;

		&--element{
			// position: relative;
			// width: 25%;
			// height: 100%;
		}
	}
}


</style>